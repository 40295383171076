<template>
  <div :id="`duHolder_${duid}`" />
</template>
<script>
import 'babel-polyfill'
import 'es6-promise/auto'

export default {
  name: 'DEXT5Upload',
  props: {
    single: { type: Boolean, default: false, required: false },
    category: { type: Boolean, default: false, required: false },
    id: { type: String, default: '', required: false },
    width: { type: String, default: '100%', required: false },
    height: { type: String, default: '200px', required: false },
    fileInfo: { type: Array, required: false },
    categoryInfo: { type: Array, required: false },
    fileExt: { type: String, default: '', required: false },
    fileSize: { type: String, default: '', required: false },
    buttonBarEdit: { type: Boolean, default: false, required: false }
  },
  data () {
    return {
      index: 0,
      makeDext: false,
      options: {},
      uploadingYn: 'N',
      duid: `${this.id}_du_${Math.random().toString(36).substr(2, 9)}`
    }
  },
  watch: {
    fileInfo: function () {
      //
    }
  },
  destroyed () {
    if (DEXT5UPLOAD.GetUploadByName(this.options.id)) {
      DEXT5UPLOAD.Destroy(this.options.id)
    }
    DEXT5UPLOAD.config.MaxOneFileSize = ''
  },
  mounted () {
    let handlerUrl = ''
    if (process.env.VUE_APP_COMMON_URL.startsWith('http')) {
      handlerUrl = process.env.VUE_APP_COMMON_URL
    } else if (process.env.VUE_APP_COMMON_URL.startsWith('//')) {
      handlerUrl = location.protocol + process.env.VUE_APP_COMMON_URL
    }
    handlerUrl += '/commons/dext5/handler'

    const duOptions = Object.assign(this.options, {
      type: this.type,
      id: this.duid,
      fileExt: this.fileExt,
      fileSize: this.fileSize,
      lang: this.$i18n.locale.toLowerCase(),
      width: this.width || '100%',
      height: this.height || '500px',
      uploadHolder: 'duHolder_' + this.duid,
      handlerUrl: handlerUrl,
      runtimes: 'html5',
      buttonBarEdit: this.buttonBarEdit ? 'download' : 'add,download,remove,remove_all',
      event: {
        CreationComplete: this.onCreationComplete,
        TransferComplete: this.onTransferComplete,
        BeforeFileDownload: this.beforeFileDownload,
        FinishDownloaded: this.FinishDownloaded,
        AfterAddItemEndTime: this.AfterAddItemEndTime,
        AfterAddItem: this.AfterAddItem,
        BeforeDeleteItem: this.BeforeDeleteItem,
        BeforeAddItem: this.BeforeAddItem,
        UploadBegin: this.UploadBegin,
        UploadCompleted: this.UploadCompleted
      }
    })

    if (typeof (DEXT5UPLOAD) === 'undefined') {
      this.$loadScript('/dext5upload/js/dext5upload.js')
        .then(() => {
          this.MakeUpload(duOptions)
        })
        .catch(() => {
          console.log('load fail catch')
        })
    } else {
      this.MakeUpload(duOptions)
    }
  },
  methods: {
    ResetDext: function () {
      DEXT5UPLOAD.Destroy(this.options.id)
      this.MakeUpload(this.options)
    },
    MakeUpload: function (options) {
      if (DEXT5UPLOAD.GetUploadByName(options.id)) {
        DEXT5UPLOAD.Destroy(options.id)
      }

      options = {
        ...this.options,
        ...options
      }

      if (options.fileExt) {
        DEXT5UPLOAD.config.ExtensionAllowOrLimit = '1'
        DEXT5UPLOAD.config.ExtensionArr = options.fileExt
      }

      if (options.fileSize) {
        DEXT5UPLOAD.config.MaxOneFileSize = options.fileSize
      }

      DEXT5UPLOAD.config.Width = options.width
      DEXT5UPLOAD.config.Height = options.height
      DEXT5UPLOAD.config.Runtimes = options.runtimes
      DEXT5UPLOAD.config.Lang = options.lang
      DEXT5UPLOAD.config.Event = options.event
      DEXT5UPLOAD.config.UploadHolder = options.uploadHolder
      DEXT5UPLOAD.config.ButtonBarEdit = options.buttonBarEdit
      DEXT5UPLOAD.config.UseViewOrOpenEvent = '0'
      if (this.category) {
        DEXT5UPLOAD.config.HeaderBarItem = this.$t('comp.901')
        DEXT5UPLOAD.config.HeaderBarItemWidth = '200'
        DEXT5UPLOAD.config.HeaderBarItemAlign = 'center'
      }

      if (options.handlerUrl !== '') {
        DEXT5UPLOAD.config.HandlerUrl = options.handlerUrl
      }

      new Dext5Upload(options.id)
    },
    SendFile: function () {
      // DEXT5UPLOAD.AddUploadedFile('test', originName, path, size, customValue, this.Id)
      DEXT5UPLOAD.Transfer(this.id)
    },
    afterCompose: function (uploadId) {
      let $vm = this

      $(DEXT5UPLOAD.GetUploadByName(uploadId).frameWin.document).find('#file_list').children().each(function (i, v) {
        $(v).find('ul > li > span').dblclick(function (e) {
          e.stopPropagation()
        })

        if ($vm.category) {
          const customHeaderObj = $(v).find('ul > li:eq(3) > span')
          customHeaderObj.css({ textAlign: 'center' }).text('')

          const tempSelect = $('<select id="' + $vm.fileInfo[i].guid + '">')
            .appendTo(customHeaderObj)
            .css({ width: '99%', height: '15px' })
            .click(function (e) {
              $vm.fileInfo[i].category = this.value
            })

          if (!$vm.fileInfo[i].category) {
            $('<option>').val('').text($vm.$t('comp.902')).attr({ selected: true }).appendTo(tempSelect)
          }

          $vm.categoryInfo.forEach(c => {
            const tempOpt = $('<option>').val(c.value).text(c.category).appendTo(tempSelect)
            if (c.value === $vm.fileInfo[i].category) {
              tempOpt.attr({ selected: true })
            }
          })
        }
      })
    },
    // DEXT5 Upload Event
    onCreationComplete: function (uploadId) {
      this.fileInfo.forEach(f => {
        DEXT5UPLOAD.AddUploadedFile(this.index, f.originalName, '/', f.size, f.fileId, uploadId)
        DEXT5UPLOAD.SetUploadedFile(this.index, f.guid, f.originalName, '/', f.size, f.fileId, uploadId)
        this.index = this.index + 1
      })

      this.afterCompose(uploadId)

      this.makeDext = true
    },
    AfterAddItem: function (uploadID, strFileName, nFileSize, nAddItemIndex, objFile) {
      this.uploadingYn = 'Y'
    },
    onTransferComplete: function (uploadId) {
      let jsonAll = DEXT5UPLOAD.GetAllFileListForJson(uploadId)

      let originalName = jsonAll.newFile.originalName
      let size = jsonAll.newFile.size
      let guid = jsonAll.newFile.guid
      let fileId = jsonAll.newFile.responseCustomValue
      let status = jsonAll.newFile.status
      let contentType = jsonAll.newFile.mimeType
      for (let i = 0; i < originalName.length; i++) {
        let dextCategory = DEXT5UPLOAD.GetUploadByName(uploadId).frameWin.document.getElementById(jsonAll.newFile.guid)
        this.fileInfo.push({
          guid: guid[i],
          originalName: originalName[i],
          size: size[i],
          fileId: fileId[i],
          status: status[i],
          contentType: contentType[i],
          category: '',
          new: true,
          newFlag: 'Y'
        })
        DEXT5UPLOAD.SetUploadedFile(this.index, guid[i], originalName[i], '/', size[i], fileId[i], uploadId)
        this.index = this.index + 1
      }

      this.afterCompose(uploadId)
      this.$emit('completed', this.fileInfo)
      this.uploadingYn = 'N'
    },
    beforeFileDownload: function (uploadID, nWebFile, strItemKey, strItemOrgName, strItemUrlOrPath, largeFiles, isLastEvent) {
      return true
    },
    FinishDownloaded: function (uploadID, nDownloadItemCount) {
      //
    },
    AfterAddItemEndTime: function (uploadID) {
      DEXT5UPLOAD.TransferEx(uploadID)
    },
    BeforeAddItem: function (uploadID, strFileName, nFileSize, nAddItemIndex, strFileLocalPath, objFile) {
      if (this.single) {
        let count = DEXT5UPLOAD.GetTotalFileCount(uploadID)
        if (count > 1 || nAddItemIndex > 1) {
          let obj = {
            alertType: 'simple',
            customCloseBtnClass: 'button blue lg simplert-test1',
            customCloseBtnText: '확인',
            useIcon: false,
            customClass: 'simple_alert',
            message: this.$t('common.FILE_UPLOAD.012'), // 하나의 파일만 업로드해주세요
            type: 'info'
          }
          this.$ekmtcCommon.alertDefault(this.$t('common.FILE_UPLOAD.012'))
          return false
        }
      }
    },
    BeforeDeleteItem: function (uploadID, strWebFile, strItemKey, strItemUrlOrPath, nDeleteItemIndex) {
      for (let i = 0; i < this.fileInfo.length; i++) {
        if (i + 1 === nDeleteItemIndex) {
          this.fileInfo.splice(i, 1)
        }
      }
      // this.$emit('update', this.fileInfo)
    },
    UploadBegin: function () {
      //
    },
    UploadCompleted: function () {
      //
    },
    getFileInfo: function () {
      return this.fileInfo
    },
    deleteAll: function () {
      DEXT5UPLOAD.DeleteAllFile(this.options.id)
    },
    setFileInfo: function () {
      if (this.makeDext) {
        this.fileInfo.forEach(f => {
          DEXT5UPLOAD.AddUploadedFile(this.index, f.originalName, '/', f.size, f.fileId, this.options.id)
          DEXT5UPLOAD.SetUploadedFile(this.index, f.guid, f.originalName, '/', f.size, f.fileId, this.options.id)
          this.index = this.index + 1
        })

        this.afterCompose(uploadId)
      }
    },
    isUploading () {
      return this.uploadingYn === 'Y'
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
